import { useState } from 'react';
import { Heart, Info as InfoIcon, Navigation, Users } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getTranslation } from '../../../i18n/getTranslation';
import { setCurrentTab, setTutorialStarted, setViewDonationsPopup } from '../../../redux/slices/components';
import ThanksPopup from '../../popups/ThanksPopup';

const Info = () => {
	const dispatch = useDispatch();
	const [acknowledgesVisible, setAcknowledgesVisible] = useState(false);

	const startTutorial = () => {
		setTimeout(() => dispatch(setTutorialStarted(true)), 60);
		dispatch(setCurrentTab(0));
	};

	const setDonationPopup = value => {
		dispatch(setViewDonationsPopup(value));
	};

	return (
		<>
			<a className='item icon-button' href='https://lecto.app/es/about' target='_blank' rel='noopener noreferrer'>
				<InfoIcon color='#26334E' size={50} />
				<p>{getTranslation('about')}</p>
			</a>
			<div className='item icon-button' onClick={() => setDonationPopup(true)}>
				<Heart color='#26334E' size={50} />
				<p>{getTranslation('support-us')}</p>
			</div>
			<div className='item icon-button' onClick={startTutorial}>
				<Navigation color='#26334E' size={50} />
				<p>{getTranslation('view-tutorial')}</p>
			</div>
			<div className='item icon-button' onClick={() => setAcknowledgesVisible(true)}>
				<Users color='#26334E' size={50} />
				<p>{getTranslation('thanks')}</p>
			</div>
			<ThanksPopup open={acknowledgesVisible} setOpen={setAcknowledgesVisible} />
		</>
	);
};

export default Info;
