/* eslint-disable */
import { stateToHTML } from 'draft-js-export-html';
import store from '../redux/store';

import axios from 'axios';

export const getFileType = name => {
	const splittedName = name.split('.');
	return splittedName[splittedName.length - 1];
};

export const exportToDOCX = async () => {
	const { editor } = store.getState();
	const html = stateToHTML(editor.editorState);
	const docx = await getDOCXData(html);
	const button = document.createElement('a');
	button.href = window.URL.createObjectURL(new Blob([docx]));
	button.download = 'Archivo de LectO.docx';
	button.click();
};

export async function getDOCXData(html) {
	const { data } = await axios.post(
		'https://lecto-web-backend-production.up.railway.app/save-docx-new',
		{ html },
		{ responseType: 'blob' }
	);
	return data;
}
