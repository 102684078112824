import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import toast from 'react-hot-toast';
import { getTranslation } from '../../i18n/getTranslation';
import { getFileType } from '../../utils/ExportFile';
import { resizeImage } from '../../utils/Images';
import { saveToLocalStorage } from '../../utils/LocalStorage';

export const setFileBlocks = createAsyncThunk('editor/openFile', async data => {
	const toastId = toast.loading(getTranslation('toast-file-loading'));
	try {
		const fileType = getFileType(data.name);
		let route = 'convert-docx';
		if (fileType === 'rtf') route = 'rtf-to-text';

		const formData = new FormData();
		formData.append('file', data);

		const response = await axios.post(`https://lecto-web-backend-production.up.railway.app/${route}`, formData, {
			headers: { 'content-type': `multipart/form-data` },
		});

		if (fileType === 'rtf') {
			// convert text to blocks
			console.log(response.data.result);
			const paragraphs = response.data.result.split('\n').map(p => `<p>${p}</p>`);
			const blocks = htmlToDraft(paragraphs.join(''));
			toast.success(getTranslation('toast-file-success'), { id: toastId });
			return blocks;
		}

		const blocks = htmlToDraft(response.data.html.value);
		toast.success(getTranslation('toast-file-success'), { id: toastId });
		return blocks;
	} catch (err) {
		toast.error(getTranslation('toast-file-error'), { id: toastId });
		return err;
	}
});

export const addImageData = createAsyncThunk('editor/addImageData', async (data, thunkAPI) => {
	const toastId = toast.loading(getTranslation('toast-img-loading'));
	const { lang } = thunkAPI.getState().config;

	const langs = {
		en: 'eng',
		es: 'spa',
		it: 'ita',
		fr: 'fre',
		de: 'ger',
		default: 'eng',
	};

	try {
		const texts = [];

		for (let i = 0; i < data.length; i++) {
			const formData = new FormData();
			const file = data[i];

			// resizear a un máximo de 1200 de width o de height (se mantiene el aspect ratio)
			const resizedImage = await resizeImage(file);
			formData.append('file', resizedImage);
			formData.append('apikey', '467a5070d888957');
			formData.append('language', langs[lang] || langs.default);
			formData.append('scale', true);

			const response = await axios.post('https://api.ocr.space/parse/image', formData, {
				headers: { 'Content-Type': `multipart/form-data` },
			});

			texts.push(response.data.ParsedResults[0].ParsedText);
		}
		toast.success(getTranslation('toast-img-success'), { id: toastId });

		return texts;
	} catch (err) {
		toast.error(getTranslation('toast-img-error'), { id: toastId });
		return err;
	}
});

const slice = createSlice({
	name: 'editor',
	initialState: {
		text: '',
		editorState: {},
		fileBlocks: { data: {}, loading: false, error: null },
		imageData: { data: {}, loading: false, error: null },
		clipboardData: { data: {}, loading: false, error: null },
		triggerNewFile: 0,
	},
	reducers: {
		saveEditorState: (state, action) => {
			const getText = () => {
				const blocks = convertToRaw(action.payload.getCurrentContent()).blocks;
				const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
				return value;
			};
			state.text = getText();
			state.editorState = action.payload.getCurrentContent();
			const rawState = convertToRaw(action.payload.getCurrentContent());
			saveToLocalStorage('editorState', rawState);
		},
		removeErrors: state => {
			state.fileBlocks.error = null;
			state.imageData.error = null;
		},
		setClipboardData: (state, action) => {
			state.clipboardData.data = action.payload;
		},
		emptyDataField: (state, action) => {
			state[action.payload] = { data: {}, loading: false, error: null };
		},
		newFile: (state, action) => {
			state.triggerNewFile += 1;
		},
	},
	extraReducers: {
		[setFileBlocks.fulfilled]: (state, action) => {
			state.fileBlocks.data = action.payload;
			state.fileBlocks.loading = false;
			state.fileBlocks.error = null;
		},
		[setFileBlocks.pending]: (state, action) => {
			state.fileBlocks.loading = true;
		},
		[setFileBlocks.rejected]: (state, action) => {
			state.fileBlocks.loading = false;
			state.fileBlocks.error = action.error;
		},
		[addImageData.fulfilled]: (state, action) => {
			state.imageData.data = action.payload;
			state.imageData.loading = false;
			state.imageData.error = null;
		},
		[addImageData.pending]: (state, action) => {
			state.imageData.loading = true;
		},
		[addImageData.rejected]: (state, action) => {
			state.imageData.loading = false;
			state.imageData.error = action.error;
		},
	},
});

export const { saveEditorState, removeErrors, setClipboardData, emptyDataField, newFile } = slice.actions;

export default slice.reducer;
